import React, { PropsWithChildren, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import Helmet from "react-helmet";
import { MainNavOvens } from "../components/MainNav/MainNavOvens";
import styled from "styled-components";
import { useOven } from "../hooks/useOven";

export const OvensLayout = ({ children }: PropsWithChildren<{}>) => {
  const { ovenId } = useParams<any>();
  const { pathname } = useLocation();
  const history = useHistory();

  const { currentOven } = useOven();

  useEffect(() => {
    document.getElementById("content")?.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (ovenId && !currentOven) {
      history.push(`/ovens`);
    }
  }, [ovenId, currentOven, history]);

  return (
    <StyledWrap>
      <Helmet title="Ovens | Tablet" />
      <MainNavOvens />
      <StyledContent id="content">{children}</StyledContent>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const StyledContent = styled.div`
  flex: 1;
  padding: 8px;
  overflow-y: scroll;
  background: #eee;
`;
