import {
  ProductGroupSelectionProvider,
  useProductGroupSelection,
} from "../../../context/ProductGroupSelectionContext";

import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { Message } from "../../../components/Message/Message";
import { OvensLayout } from "../../../layouts/OvensLayout";
import { ProductGroupSelection } from "../../../components/ProductGroupSelection/ProductGroupSelection";
import { SpinnerPage } from "../../../components/SpinnerPage";
import styled from "styled-components";
import { useMarkAsStatus } from "./useMarkAsStatus";
import { useOvenFinishDay } from "./useOvenFinishDay";
import { useParams } from "react-router";

const StyledProduction = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
`;

type TProps = {};

const OvenMarkBakedBrokenPageApp = (props: TProps) => {
  const { ovenId } = useParams<any>();

  const { productGroups, refreshingProductGroups } = useOvenFinishDay();
  const { selectedProductIds, summary } = useProductGroupSelection();
  const { submitting, markAsBaked, markAsBroken } = useMarkAsStatus(ovenId);

  const renderContent = () => {
    if (refreshingProductGroups) return <SpinnerPage />;
    if (productGroups?.length === 0) {
      return <Message>No modeled production right now</Message>;
    }
    return (
      <>
        <StyledProduction>
          {productGroups?.map(({ productId, productCode, amount }) => {
            return (
              <ProductGroupSelection
                productId={productId}
                productCode={productCode}
                amount={amount}
              />
            );
          })}
        </StyledProduction>
        <div style={{ display: "flex" }}>
          {selectedProductIds?.length > 0 && (
            <ConfirmButton
              label="Mark as broken"
              buttonStyle={{
                width: "50%",
                backgroundColor: "red",
                marginRight: 10,
              }}
              onConfirm={() => markAsBroken({ productIds: selectedProductIds })}
              loading={submitting}
            >
              Are you sure these products are broken?
              <br />
              {summary}
            </ConfirmButton>
          )}
          {selectedProductIds?.length > 0 && (
            <ConfirmButton
              label="Mark as baked"
              buttonStyle={{ width: "50%" }}
              onConfirm={() => markAsBaked({ productIds: selectedProductIds })}
              loading={submitting}
            >
              Are you sure these products are baked?
              <br />
              {summary}
            </ConfirmButton>
          )}
        </div>
      </>
    );
  };

  return <OvensLayout>{renderContent()}</OvensLayout>;
};

export const OvenMarkBakedBrokenPage = () => (
  <ProductGroupSelectionProvider>
    <OvenMarkBakedBrokenPageApp />
  </ProductGroupSelectionProvider>
);
