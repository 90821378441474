import React, { useContext, useState } from "react";

import { TTeam } from "@vierkant-world/shared";

type TTeamContext = {
  currentTeam?: TTeam;
  setCurrentTeam: (team?: TTeam) => void;
};

const TeamContext = React.createContext({} as TTeamContext);

export const TeamProvider: React.FC = ({ children }) => {
  const [currentTeam, setCurrentTeam] = useState<TTeam>();

  console.log({ currentTeam, setCurrentTeam });

  return (
    <TeamContext.Provider value={{ currentTeam, setCurrentTeam }}>
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  const context = useContext(TeamContext);
  console.log({ context });
  return context;
};
