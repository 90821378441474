import {
  TProduction,
  TTeamHistoryResponse,
  dateUtils,
} from "@vierkant-world/shared";
import useSWR, { mutate } from "swr";

import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { Message } from "../../../components/Message/Message";
import { SpinnerPage } from "../../../components/SpinnerPage";
import { TeamsLayout } from "../../../layouts/TeamsLayout";
import axios from "axios";
import { handleError } from "../../../utils/error";
import { message } from "antd";
import styled from "styled-components";
import { useParams } from "react-router";

export const TeamHistoryPage = () => {
  const { teamId } = useParams<any>();

  const url = `/teams/${teamId}/history`;

  const fetcher = () =>
    axios.get<TTeamHistoryResponse>(url).then((_) => _.data);

  const {
    data: history,
    isValidating: refreshingModeledProduction,
    mutate: refresh,
  } = useSWR(url, { fetcher });

  const undoModeled = async (productionItem: Partial<TProduction>) => {
    try {
      await axios.put(`/production/${productionItem.id}/undo-modeled`);
      message.success(
        `Moved ${productionItem?.product?.code} back to planning`
      );
      refresh();
      mutate(`/teams/${teamId}/today`);
    } catch (error) {
      handleError(error);
    }
  };

  const renderContent = () => {
    if (refreshingModeledProduction) return <SpinnerPage />;
    if (history?.length === 0) {
      return <Message>No history yet</Message>;
    }
    return (
      <StyledList>
        {history?.map(({ date, production }) => (
          <StyledDayGroup>
            <StyledDate>{date}</StyledDate>
            {production?.map((productionItem) => {
              return (
                <StyledProductionItem key={productionItem.id}>
                  <ConfirmButton
                    label="Undo"
                    onConfirm={() => undoModeled(productionItem)}
                  >
                    Are you sure you want to move{" "}
                    <strong>{productionItem.product?.code}</strong>&#160; back
                    to your planning?
                  </ConfirmButton>
                  <StyledProductCode>
                    <strong>{productionItem.product?.code}</strong> finished
                    modeling at{" "}
                    {dateUtils.format(productionItem.modeledAt, "HH:mm")}
                  </StyledProductCode>
                </StyledProductionItem>
              );
            })}
          </StyledDayGroup>
        ))}
      </StyledList>
    );
  };

  return <TeamsLayout>{renderContent()}</TeamsLayout>;
};

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDayGroup = styled.div`
  padding: 8px;
  border: 2px solid #ccc;
  background: #fff;
  margin-bottom: 16px;
`;

const StyledDate = styled.h3`
  font-size: 16px;
  font-weight: 600;
`;

const StyledProductionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 4px;
  &:last-child {
    border: 0;
  }
`;

const StyledProductCode = styled.p`
  font-size: 16px;
  margin: 0;
  margin-left: 16px;
`;
