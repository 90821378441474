import {
  EProductionStatus,
  TOvenHistoryResponse,
  dateUtils,
} from "@vierkant-world/shared";

import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { Message } from "../../../components/Message/Message";
import { OvensLayout } from "../../../layouts/OvensLayout";
import { SpinnerPage } from "../../../components/SpinnerPage";
import axios from "axios";
import { handleError } from "../../../utils/error";
import { message } from "antd";
import moment from "moment";
import styled from "styled-components";
import useSWR from "swr";

export const OvenHistoryPage = () => {
  const url = `/ovens/history`;
  const fetcher = () =>
    axios.get<TOvenHistoryResponse>(url).then((_) => _.data);
  const {
    data: history,
    isValidating: refreshingModeledProduction,
    mutate,
  } = useSWR(url, { fetcher });

  const renderContent = () => {
    if (refreshingModeledProduction) return <SpinnerPage />;
    if (history?.length === 0) {
      return <Message>No history yet</Message>;
    }
    return (
      <StyledList>
        {history?.map(({ date, production }) => (
          <StyledDayGroup>
            <StyledDate>{date}</StyledDate>
            {production?.map((productionItem) => {
              let timestamp: string = "";
              if (productionItem.status === EProductionStatus.baked) {
                timestamp = moment(productionItem.bakedAt).format(
                  `DD/MM/'YY - HH:mm`
                );
              }
              if (productionItem.status === EProductionStatus.broken) {
                timestamp = moment(productionItem.brokenAt).format(
                  `DD/MM/'YY - HH:mm`
                );
              }

              return (
                <StyledProductionItem key={productionItem.id}>
                  <ConfirmButton
                    label="Undo"
                    onConfirm={async () => {
                      try {
                        await axios.put(
                          `/ovens/undo-${productionItem.status}`,
                          { production: productionItem }
                        );
                        message.success(
                          `Undo ${productionItem?.product?.code} from status ${productionItem.status}`
                        );
                        mutate();
                      } catch (error) {
                        handleError(error);
                      }
                    }}
                  >
                    Are you sure want to change status of <br />
                    <strong>{productionItem.product?.code}</strong>&#160; from{" "}
                    {productionItem.status} to modeled?
                  </ConfirmButton>
                  <StyledProductCode>
                    <strong>{productionItem.product?.code}</strong>{" "}
                    {productionItem.status} at {timestamp}
                  </StyledProductCode>
                  <StyledOvenName>{productionItem.oven?.name}</StyledOvenName>
                  <StyledProductionId>{productionItem.id}</StyledProductionId>
                </StyledProductionItem>
              );
            })}
          </StyledDayGroup>
        ))}
      </StyledList>
    );
  };

  return <OvensLayout>{renderContent()}</OvensLayout>;
};

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDayGroup = styled.div`
  padding: 8px;
  border: 2px solid #ccc;
  background: #fff;
  margin-bottom: 100px;
`;

const StyledDate = styled.h3`
  font-size: 16px;
  font-weight: 600;
`;

const StyledProductionItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 4px;
  &:last-child {
    border: 0;
  }
`;

const StyledProductCode = styled.p`
  font-size: 16px;
  margin: 0;
  margin-left: 16px;
`;

const StyledOvenName = styled.span`
  color: #ccc;
  margin-left: 16px;
`;

const StyledProductionId = styled.span`
  display: inline-flex;
  margin-left: 32px;
  font-size: 10px;
  justify-self: flex-end;
  color: #ccc;
`;
