import "antd/dist/antd.min.css";
import "./global.css";

import { AuthProvider, useAuth } from "./hooks/useAuth";
import { Route, Switch } from "react-router";

import { BrowserRouter } from "react-router-dom";
import { OvenHistoryPage } from "./pages/ovens/OvenHistoryPage/OvenHistoryPage";
import { OvenMarkBakedBrokenPage } from "./pages/ovens/OvenMarkBakedBrokenPage/OvenMarkBakedBrokenPage";
import { OvenProvider } from "./hooks/useOven";
import { OvensOverviewPage } from "./pages/ovens/OvensOverviewPage/OvensOverviewPage";
import { SWRConfig } from "swr";
import { SpinnerPage } from "./components/SpinnerPage";
import { TeamFinishDayPage } from "./pages/teams/TeamFinishDayPage/TeamFinishDayPage";
import { TeamHistoryPage } from "./pages/teams/TeamHistoryPage/TeamHistoryPage";
import { TeamProvider } from "./hooks/useTeam";
import { TeamsOverviewPage } from "./pages/teams/TeamsOverviewPage/TeamsOverviewPage";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {
  const { checkingToken } = useAuth();

  if (checkingToken) return <SpinnerPage />;

  return (
    <Switch>
      <Route path="/teams/:teamId/finish-day">
        <TeamFinishDayPage />
      </Route>
      <Route path="/teams/:teamId/history">
        <TeamHistoryPage />
      </Route>
      <Route path="/teams">
        <TeamsOverviewPage />
      </Route>
      <Route path="/ovens/:ovenId/mark-baked-broken">
        <OvenMarkBakedBrokenPage />
      </Route>
      <Route path="/ovens/history">
        <OvenHistoryPage />
      </Route>
      <Route path="/ovens">
        <OvensOverviewPage />
      </Route>
      <Route path="/">
        <div style={{ padding: 20 }}>
          <a href="/ovens">Ovens</a>
          <br />
          <a href="/teams">Teams</a>
        </div>
      </Route>
    </Switch>
  );
}

const Root: React.FC = ({ children }) => {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        // refreshInterval: 3000,
      }}
    >
      <OvenProvider>
        <TeamProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </TeamProvider>
      </OvenProvider>
    </SWRConfig>
  );
};

export default Root;
