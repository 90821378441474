import React, { PropsWithChildren, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import Helmet from "react-helmet";
import { MainNavTeams } from "../components/MainNav/MainNavTeams";
import styled from "styled-components";
import { useTeam } from "../hooks/useTeam";

export const TeamsLayout = ({ children }: PropsWithChildren<{}>) => {
  const { teamId } = useParams<any>();
  const { pathname } = useLocation();
  const history = useHistory();

  const { currentTeam } = useTeam();

  useEffect(() => {
    document.getElementById("content")?.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (teamId && !currentTeam) {
      history.push(`/teams`);
    }
  }, [teamId, currentTeam, history]);

  return (
    <StyledWrap>
      <Helmet title="Teams | Tablet" />
      <MainNavTeams />
      <StyledContent id="content">{children}</StyledContent>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const StyledContent = styled.div`
  flex: 1;
  padding: 8px;
  overflow-y: scroll;
  background: #eee;
`;
