import { AxiosError } from "axios";
import { errorUtils } from "@vierkant-world/shared";
import { message } from "antd";

export const handleError = (error: AxiosError | unknown) => {
  const errorMessage = errorUtils.getMessage(error);
  message.error(errorMessage);
  // TODO: Send to Sentry
  // Sentry.captureException(new Error(errorMessage));
  // throw error;
};
