import { Button } from "antd";
import styled from "styled-components";
import { useProductGroupSelection } from "../../context/ProductGroupSelectionContext";

const StyledWrap = styled.div<{ selected: number }>`
  padding: 4px;
  padding-left: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  background-color: ${({ selected }) => (selected > 0 ? "#000" : "#ccc")};
  color: ${({ selected }) => (selected > 0 ? "#fff" : "#000")};
`;

const StyledText = styled.span`
  font-size: 15px;
`;

type TProps = {
  productId: number;
  productCode: string;
  amount: number;
};

export const ProductGroupSelection = ({
  productId,
  productCode,
  amount: max,
}: TProps) => {
  const { selectAmount, getSelectedAmount } = useProductGroupSelection();
  const selected = getSelectedAmount(productId);
  return (
    <StyledWrap selected={selected}>
      <StyledText>
        {selected}x {productCode}
      </StyledText>
      <Button.Group style={{ marginLeft: 8 }}>
        <Button
          size="middle"
          onClick={() =>
            selectAmount({ productId, productCode, amount: selected - 1 })
          }
        >
          -
        </Button>
        <Button
          size="middle"
          onClick={() =>
            selectAmount({ productId, productCode, amount: selected + 1, max })
          }
        >
          +
        </Button>
      </Button.Group>
    </StyledWrap>
  );
};
