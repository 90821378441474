import React from "react";
import { TabletButton } from "../TabletButton";
import { TeamFinishedTodayBar } from "../TeamFinishedTodayBar/TeamFinishedTodayBar";
import styled from "styled-components";
import { useTeam } from "../../hooks/useTeam";

type TProps = {};

export const MainNavTeams = (props: TProps) => {
  const { currentTeam, setCurrentTeam } = useTeam();

  console.log({ currentTeam });

  return (
    <>
      <StyledWrap>
        <TabletButton to="/teams" onClick={() => setCurrentTeam(undefined)}>
          Home
        </TabletButton>
        {currentTeam && (
          <TabletButton to={`/teams/${currentTeam?.id}/finish-day`}>
            Finish Day
          </TabletButton>
        )}
        {currentTeam && (
          <TabletButton to={`/teams/${currentTeam?.id}/history`}>
            History
          </TabletButton>
        )}

        {currentTeam && (
          <>
            <StyledCurrentTeam>Team: {currentTeam?.name}</StyledCurrentTeam>
            <TabletButton
              to={`/teams`}
              onClick={() => setCurrentTeam(undefined)}
            >
              Logout
            </TabletButton>
          </>
        )}
      </StyledWrap>
      {currentTeam && <TeamFinishedTodayBar teamId={currentTeam.id} />}
    </>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: #ccc;
  align-items: center;
  padding: 8px;
`;

const StyledCurrentTeam = styled.p`
  font-weight: 500;
  margin-left: auto;
  margin-right: 16px;
`;
