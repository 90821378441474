import {
  ProductGroupSelectionProvider,
  useProductGroupSelection,
} from "../../../context/ProductGroupSelectionContext";
import { TProduction, useSelection } from "@vierkant-world/shared";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import useSWR, { mutate } from "swr";

import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { DebugData } from "../../../components/DebugData/DebugData";
import { Message } from "../../../components/Message/Message";
import { SpinnerPage } from "../../../components/SpinnerPage";
import { TeamFinishProduct } from "./TeamFinishProduct";
import { TeamsLayout } from "../../../layouts/TeamsLayout";
import axios from "axios";
import { handleError } from "../../../utils/error";
import { message } from "antd";
import { orderBy } from "lodash";
import styled from "styled-components";

const StyledProductGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
`;

const TeamFinishDayPageApp = () => {
  const history = useHistory();
  const { teamId } = useParams<any>();

  const { selectedProductIds: productIds, summary } =
    useProductGroupSelection();

  type TResponse = {
    productId: number;
    productCode: string;
    amount: number;
  }[];
  const url = `/teams/${teamId}/finish-day`;
  const fetcher = () => axios.get<TResponse>(url).then((_) => _.data);
  const {
    data: plannedProductGroups,
    isValidating: refreshingPlannedProduction,
  } = useSWR(url, { fetcher });

  const [submitting, setSubmitting] = useState<boolean>(false);
  const finishDay = useCallback(async () => {
    try {
      if (productIds?.length === 0) {
        throw new Error("Please select a production item");
      }
      setSubmitting(true);
      await axios.put(url, { productIds }).then((_) => _.data);
      message.success("Finished day");
      history.push(`/teams/${teamId}/history`);
      mutate(`/teams/${teamId}/today`);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  }, [url, productIds, teamId, history]);

  const renderContent = () => {
    if (refreshingPlannedProduction) return <SpinnerPage />;
    if (plannedProductGroups?.length === 0) {
      return <Message>No planned production for your team</Message>;
    }
    return (
      <>
        <StyledProductGroups>
          {plannedProductGroups?.map(({ productId, productCode, amount }) => (
            <>
              <TeamFinishProduct
                key={productId}
                productId={productId}
                productCode={productCode}
                amount={amount}
              />
            </>
          ))}
        </StyledProductGroups>
        {productIds?.length > 0 && (
          <ConfirmButton
            label="Finish Day"
            buttonStyle={{ width: "100%" }}
            onConfirm={finishDay}
            loading={submitting}
          >
            Are you sure you finished these products?
            <br />
            {summary}
          </ConfirmButton>
        )}
      </>
    );
  };

  return (
    <TeamsLayout>
      {renderContent()}
      <DebugData data={{ productIds }} />
    </TeamsLayout>
  );
};

export const TeamFinishDayPage = () => (
  <ProductGroupSelectionProvider>
    <TeamFinishDayPageApp />
  </ProductGroupSelectionProvider>
);
