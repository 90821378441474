import React from "react";
import { Spinner } from "../Spinner/Spinner";
import styled from "styled-components";

export type TSpinnerPageProps = { title?: string };

export const SpinnerPage = ({ title = "One moment.." }: TSpinnerPageProps) => {
  return (
    <StyledWrap>
      <Spinner />
      <StyledTitle>{title}</StyledTitle>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.h1`
  margin-top: 32px;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
`;
