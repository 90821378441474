import { TOven } from "@vierkant-world/shared";
import axios from "axios";
import useSWR from "swr";

export const useOvens = () => {
  const fetcher = () => axios.get<TOven[]>(`/ovens`).then((_) => _.data);
  const { data: ovens } = useSWR(`/ovens`, { fetcher });

  return { ovens };
};
