import React from "react";
import styled from "styled-components";

export const Message: React.FC = ({ children }) => {
  return <StyledMessage>{children}</StyledMessage>;
};

const StyledMessage = styled.p`
  font-size: 21px;
  line-height: 28px;
`;
