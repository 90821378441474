import { TabletButton } from "../TabletButton";
import styled from "styled-components";
import { useOven } from "../../hooks/useOven";

type TProps = {};

export const MainNavOvens = (props: TProps) => {
  const { currentOven, setCurrentOven } = useOven();

  console.log({ currentOven });

  return (
    <StyledWrap>
      <TabletButton to="/ovens" onClick={() => setCurrentOven(undefined)}>
        Home
      </TabletButton>
      {/* {currentOven && (
        <TabletButton to={`/ovens/${currentOven?.id}/finish-day`}>
          Finish Day
        </TabletButton>
      )} */}
      <TabletButton to={`/ovens/history`}>History</TabletButton>

      {currentOven && (
        <>
          <StyledCurrentOven>{currentOven?.name}</StyledCurrentOven>
          <TabletButton to={`/ovens`} onClick={() => setCurrentOven(undefined)}>
            Logout
          </TabletButton>
        </>
      )}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: #ccc;
  align-items: center;
  padding: 8px;
`;

const StyledCurrentOven = styled.p`
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  margin-left: auto;
  margin-right: 16px;
`;
