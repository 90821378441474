import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useQueryParams } from "./useQueryParams";

type TAuthContext = {
  token?: string;
  checkingToken?: boolean;
};

const AuthContext = React.createContext({} as TAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const tokenFromUrl = useQueryParams<{ token: string }>().token;
  const tokenFromStorage = localStorage.getItem("token");
  const guessedToken = tokenFromUrl || tokenFromStorage;

  const [checkingToken, setCheckingToken] = useState(true);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (guessedToken) {
      axios.defaults.headers = {
        ...axios.defaults.headers,
        authorization: guessedToken,
      };
      localStorage.setItem("token", guessedToken);
      setToken(guessedToken);
    }
    setCheckingToken(false);
  }, [guessedToken]);

  return (
    <AuthContext.Provider value={{ token, checkingToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
