import { TabletButton } from "../../../components/TabletButton";
import { TeamsLayout } from "../../../layouts/TeamsLayout";
import { useHistory } from "react-router";
import { useTeam } from "../../../hooks/useTeam";
import { useTeams } from "../../../hooks/useTeams";

type TProps = {};

export const TeamsOverviewPage = (props: TProps) => {
  const history = useHistory();
  const { setCurrentTeam } = useTeam();
  const { teams } = useTeams();
  return (
    <TeamsLayout>
      {teams?.map((team) => (
        <TabletButton
          onClick={() => {
            setCurrentTeam(team);
            history.push(`/teams/${team.id}/finish-day`);
          }}
        >
          {team.name}
        </TabletButton>
      ))}
    </TeamsLayout>
  );
};
