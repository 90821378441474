import axios from "axios";
import useSWR from "swr";

export const useOvenFinishDay = () => {
  const url = `/ovens/finish-day`;

  const fetcher = () =>
    axios
      .get<{ productId: number; productCode: string; amount: number }[]>(url)
      .then((_) => _.data);

  const { data: productGroups, isValidating: refreshingProductGroups } = useSWR(
    url,
    { fetcher }
  );

  return { productGroups, refreshingProductGroups };
};
