import { Button, Modal, Popconfirm } from "antd";
import React, { PropsWithChildren, useState } from "react";
import styled, { CSSProperties } from "styled-components";

type TProps = PropsWithChildren<{
  label?: string;
  buttonStyle?: CSSProperties;
  title?: string;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}>;

export const ConfirmButton = ({
  label = "Button",
  buttonStyle,
  title,
  loading = false,
  onConfirm,
  onCancel,
  children,
}: TProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <>
      <Button
        type="primary"
        size="large"
        loading={loading}
        style={buttonStyle}
        onClick={() => setModalVisible(true)}
      >
        {label}
      </Button>
      <Modal
        visible={modalVisible}
        title={title}
        okText="Confirm"
        okButtonProps={{ size: "large", loading: isSubmitting }}
        onOk={async () => {
          try {
            setSubmitting(true);
            await onConfirm?.();
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        }}
        cancelButtonProps={{ size: "large" }}
        onCancel={() => setModalVisible(false)}
      >
        {children}
      </Modal>
    </>
  );
};
