import { OvensLayout } from "../../../layouts/OvensLayout";
import { TabletButton } from "../../../components/TabletButton";
import { useHistory } from "react-router";
import { useOven } from "../../../hooks/useOven";
import { useOvens } from "../../../hooks/useOvens";

type TProps = {};

export const OvensOverviewPage = (props: TProps) => {
  const history = useHistory();
  const { setCurrentOven } = useOven();
  const { ovens } = useOvens();
  return (
    <OvensLayout>
      {ovens?.map((oven) => (
        <TabletButton
          key={oven.id}
          onClick={() => {
            setCurrentOven(oven);
            history.push(`/ovens/${oven.id}/mark-baked-broken`);
          }}
        >
          {oven.name}
        </TabletButton>
      ))}
    </OvensLayout>
  );
};
