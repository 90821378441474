import { CSSProperties } from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export type TSpinnerProps = {
  size?: number;
  style?: CSSProperties;
};

export const Spinner = ({ size = 32, style }: TSpinnerProps) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: size, ...style }} spin />}
    />
  );
};
