import { useLocation } from "react-router";
import { useMemo } from "react";

export const useQueryParams = <T extends {}>() => {
  console.log(useLocation().search);

  const queryString = useLocation().search;

  return useMemo(() => {
    let params: { [key: string]: string } = {};
    new URLSearchParams(queryString).forEach((value, key) => {
      params[key] = value;
    });
    return params as T;
  }, [queryString]);
};
