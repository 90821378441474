import axios from "axios";
import styled from "styled-components";
import useSWR from "swr";

type TProps = { teamId?: number };

export const TeamFinishedTodayBar = ({ teamId }: TProps) => {
  const url = `/teams/${teamId}/today`;

  const fetcher = () =>
    axios.get<{ summary?: string }>(url).then((_) => _.data);

  const { data } = useSWR(url, { fetcher });

  if (!data?.summary) return null;

  return <StyledWrap>Finished today: {data?.summary}</StyledWrap>;
};

const StyledWrap = styled.div`
  background: #27ae60;
  color: #fff;
  font-weight: 600;
  padding: 8px;
`;
