import React, { useCallback, useContext, useMemo, useState } from "react";

import { range } from "lodash";

type TProductGroupContext = {
  getSelectedAmount: (productId: number) => number;
  selectAmount: (props: {
    productId: number;
    productCode: string;
    amount: number;
    max?: number;
  }) => void;
  selectedProductIds: number[];
  summary: string;
};

const ProductGroupSelectionContext = React.createContext(
  {} as TProductGroupContext
);

export const ProductGroupSelectionProvider: React.FC = ({ children }) => {
  const [selection, setSelection] = useState<{ [key: number]: number }>({});

  const [productCodesMap, setProductCodesMap] = useState<{
    [key: string]: string;
  }>({});
  console.log({ productCodesMap });

  const getSelectedAmount = useCallback(
    (productId) => {
      return selection[productId] || 0;
    },
    [selection]
  );

  const summary = useMemo(() => {
    return Object.keys(selection)
      .map((productId: any) => {
        const amount = selection[productId];
        const productCode = productCodesMap[productId];
        return `${amount}x ${productCode}`;
      })
      .join(", ");
  }, [selection, productCodesMap]);

  const selectAmount = useCallback(
    ({
      productId,
      productCode,
      amount,
      max,
    }: {
      productId: number;
      productCode: string;
      amount: number;
      max?: number;
    }) => {
      setSelection((previousState) => {
        return {
          ...previousState,
          [productId]: Math.min(max || 9999999, Math.max(0, amount || 0)),
        };
      });
      setProductCodesMap((prevValue) => {
        return {
          ...prevValue,
          [productId]: productCode,
        };
      });
    },
    []
  );

  const selectedProductIds = useMemo(() => {
    return Object.keys(selection).reduce<number[]>((acc, key) => {
      const productId = parseInt(key);
      const selected = getSelectedAmount(productId);
      if (selected && productId) {
        range(0, selected).forEach(() => acc.push(productId));
      }
      return acc;
    }, []);
  }, [selection, getSelectedAmount]);

  return (
    <ProductGroupSelectionContext.Provider
      value={{ getSelectedAmount, selectAmount, selectedProductIds, summary }}
    >
      {children}
    </ProductGroupSelectionContext.Provider>
  );
};

export const useProductGroupSelection = () => {
  return useContext(ProductGroupSelectionContext);
};
