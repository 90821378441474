import React, { PropsWithChildren } from "react";

import styled from "styled-components";
import { useHistory } from "react-router";

type TProps = PropsWithChildren<{
  to?: string;
  onClick?: () => void;
}>;

export const TabletButton = ({ to, onClick, children }: TProps) => {
  const history = useHistory();
  return (
    <StyledWrap
      onClick={() => {
        onClick?.();
        if (to) history.push(to);
      }}
    >
      {children}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  color: #fff;
  background: #000;
  height: 42px;
  padding: 0 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
  margin-bottom: 8px;
`;
