import { useCallback, useState } from "react";

import { TProduction } from "@vierkant-world/shared";
import axios from "axios";
import { handleError } from "../../../utils/error";
import { message } from "antd";
import { useHistory } from "react-router";

export const useMarkAsStatus = (ovenId?: number) => {
  const history = useHistory();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const markAs = useCallback(
    async (status: "baked" | "broken", productIds: number[]) => {
      try {
        setSubmitting(true);
        await axios
          .put(`/ovens/${ovenId}/${status}`, { productIds })
          .then((_) => _.data);
        message.success(`Marked as ${status}`);
        history.push(`/ovens/history`);
      } catch (error) {
        handleError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [ovenId, history]
  );

  const markAsBaked = useCallback(
    ({ productIds }: { productIds: number[] }) => {
      return markAs("baked", productIds);
    },
    [markAs]
  );

  const markAsBroken = useCallback(
    ({ productIds }: { productIds: number[] }) => {
      return markAs("broken", productIds);
    },
    [markAs]
  );

  return { submitting, markAsBaked, markAsBroken };
};
