import React, { useContext, useState } from "react";

import { TOven } from "@vierkant-world/shared";

type TOvenContext = {
  currentOven?: TOven;
  setCurrentOven: (team?: TOven) => void;
};

const OvenContext = React.createContext({} as TOvenContext);

export const OvenProvider: React.FC = ({ children }) => {
  const [currentOven, setCurrentOven] = useState<TOven>();

  console.log({ currentOven, setCurrentOven });

  return (
    <OvenContext.Provider value={{ currentOven, setCurrentOven }}>
      {children}
    </OvenContext.Provider>
  );
};

export const useOven = () => {
  const context = useContext(OvenContext);
  console.log({ context });
  return context;
};
