import styled, { CSSProperties } from "styled-components";

import React from "react";

export type TDebugDataProps = {
  data?: any;
  style?: CSSProperties;
};

export const DebugData = ({ data, style }: TDebugDataProps) => {
  if (process.env.NODE_ENV !== "development") return null;
  // return null;
  return <StyledWrap style={style}>{JSON.stringify(data, null, 2)}</StyledWrap>;
};

const StyledWrap = styled.p`
  display: flex;
  flex-direction: column;
  background: #eee;
  color: #000;
  padding: 20px;
  border-radius: 5px;

  @media print {
    display: none;
  }
`;
