import { TTeam } from "@vierkant-world/shared";
import axios from "axios";
import useSWR from "swr";

export const useTeams = () => {
  const fetcher = () => axios.get<TTeam[]>(`/teams`).then((_) => _.data);
  const { data: teams } = useSWR(`/teams`, { fetcher });

  return { teams };
};
